import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './../../../app/shared/confirm-dialog/confirm-dialog.component.html',
    styleUrls: ['./../../../app/shared/confirm-dialog/confirm-dialog.component.scss'],
    standalone: false
})
export class ConfirmDialogComponent {
  public confirmMessage:string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) { }

  public close(): void {
    this.dialogRef.close();
  }
}
