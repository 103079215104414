import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { CustomerService } from 'src/@omnial/_services/customer/customer.service';
import { PageState } from 'src/@omnial/_models/catalog/pageState.model';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Product } from 'src/@omnial/_models/catalog/product.model';
import { TrackingService } from 'src/@omnial/_services/external/tracking.service';
import { CanonicalService } from 'src/@omnial/_services/navigation/canonical.service';
import { StarShipItService } from 'src/@omnial/_services/order/star-shipping.service';
import { StarShipItSettings } from 'src/@omnial/_models/order/star-shipping.model';
import { CurrencyService } from 'src/@omnial/_services/price/currency.service';
import { AppSettings } from 'src/app/app.settings';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-header-desktop',
    templateUrl: './../../../app/shared/header-desktop/header-desktop.component.html',
    styleUrls: ['./../../../app/shared/header-desktop/header-desktop.component.scss'],
    standalone: false
})

export class HeaderDesktopComponent implements OnInit, OnDestroy {
  @Input() inputSideNav: MatSidenav;
  @Input() menuPosition: string;
  @Input() menuOverflow: string = 'visible';
  @Input() menuTop: string;
  @Input() menuBackground = environment.topMenuBackground;
  @Input() menuLogo = true;
  public seName = '';
  public customer: Customer;
  public isCheckout = false;
  public showHam = true;
  public loggedIn = false;
  public shippingSettings: StarShipItSettings;
  public subscriptions: Subscription[] = [];

  constructor(
    public settings: AppSettings,
    public customerService: CustomerService,
    public location: Location,
    public trackingService: TrackingService,
    public canonicalService: CanonicalService,
    public router: Router,
    public starShipItService: StarShipItService,
    public currencyService: CurrencyService) {
    const navEndEvents = router.events.pipe(filter(event => event instanceof NavigationEnd));
    this.subscriptions.push(navEndEvents.subscribe((event: NavigationEnd) => {
      if (event instanceof NavigationEnd) {
        if (localStorage.getItem('token') && this.customer) {
          this.loggedIn = true;
        } else {
          this.loggedIn = false;
        }
        if (event.url.startsWith('/checkout')) {
          this.isCheckout = true;
        }
      }
      const urlParts = event?.urlAfterRedirects?.split('/');
      if (urlParts) {
        this.seName = urlParts[urlParts.length - 1];
      }
    }));
    if (this.location.path().startsWith('/checkout')) {
      this.isCheckout = true;
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(this.customerService.customer.subscribe((res) => {
      this.customer = res as Customer;
      if (localStorage.getItem('token') && this.customer) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    }));
    this.subscriptions.push(this.starShipItService.starShipItSettings.subscribe(res => {
      if (res) {
        this.shippingSettings = res as StarShipItSettings;
      }
    }));
    if (window.scrollY === 0) {
      this.showHam = false;
    } else {
      this.showHam = false;
    }
  }

  public search(): void { }

  @HostListener('window:scroll', ['$event'])
  scrollListen(event): void {
    if (window.scrollY === 0) {
      this.showHam = false;
    } else {
      this.showHam = true;
    }
  }

  public scrollToTop(): void {
    // As well as scrolling to the top we want to remove the pageOffset in the local data for a potential Category, Manufacturer or Search Result
    const pageStateString = localStorage.getItem(this.seName);
    if (pageStateString) {
      const pageState = JSON.parse(pageStateString) as PageState;
      pageState.pageNumber = 1;
      pageState.position = 0;
      pageState.scrollY = 0;
      localStorage.setItem(this.seName, JSON.stringify(pageState)); // Filters and any other info will be retained
    }
    const scrollDuration = 200;
    const scrollStep = -window.scrollY / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
  }

  productClick(product: Product): void {
    this.trackingService.pushProductClick(product, this.seName, "Mini Cart", 1);
    if (!product.canonical) {
      this.subscriptions.push(this.canonicalService.productCanonical(product).subscribe(
        res => {
          if (res) {
            this.router.navigate([`${res}/${product.seName}`]);
          } else {
            this.router.navigate([`/products/${product.id}`]);
          }
        }
      ))
    } else {
      this.router.navigate([product.canonical]);
    }
  }

  spendMore(): string {
    return this.settings.spendMore(this.shippingSettings, this.customer);
  }
}
