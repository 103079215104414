import { Component, OnInit, Input } from '@angular/core';
import { SiteMapItem } from 'src/@omnial/_models/navigation/site-map.model';
import { SiteMapService } from 'src/@omnial/_services/navigation/sitemap.service';
import { AppSettings } from 'src/app/app.settings';

@Component({
    selector: 'app-sidenav-menu',
    templateUrl: './../../../app/navigation/sidenav-menu/sidenav-menu.component.html',
    styleUrls: ['./../../../app/navigation/sidenav-menu/sidenav-menu.component.scss'],
    providers: [SiteMapService],
    standalone: false
})
export class SidenavMenuComponent implements OnInit {
  @Input() menuItems: SiteMapItem[];
  @Input() menuParentId: string;
  @Input() level: number;
  parentMenu: SiteMapItem[];

  constructor(private sitemapService: SiteMapService, public settings: AppSettings) { }

  ngOnInit(): void {
    this.parentMenu = this.menuItems.filter(item => item.parentId === this.menuParentId);
  }

  onClick(menuId: string): void {
    this.sitemapService.toggleMenuItem(menuId);
    this.sitemapService.closeOtherSubMenus(this.menuItems, menuId);
  }
}
