<mat-sidenav-container>
  <mat-sidenav #sidenav [opened]="false" mode="over" class="sidenav mat-elevation-z6">
    <div class="d-flex flex-row flex-wrap">
      <div class="flex-100">
        <button [attr.aria-label]="'Close'" class="close" (click)="sidenav.close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <app-sidenav-menu class="flex-100" *ngIf="!settings.megaMobileNav && sitemapItems && sitemapItems?.length > 0" [menuItems]="sitemapItems" [menuParentId]="'root'" [level]="0"></app-sidenav-menu>
      <app-sidenav-mega class="flex-100" *ngIf="settings.megaMobileNav && mega?.items && mega?.items.length > 0" [menuItems]="mega?.items" [menuParentId]="'root'" [level]="0"></app-sidenav-mega>
    </div>
  </mat-sidenav>
  <div id="app" class="h-100 app" [ngClass]="settings.theme" [dir]="(settings.rtl) ? 'rtl' : 'ltr'">
    <div class="d-flex flex-row flex-wrap theme-container theme-container-no-overflow">
      <app-header-desktop class="flex-100 d-none d-lg-block"></app-header-desktop>
      <app-header-mobile class="flex-100 d-lg-none" [inputSideNav]="sidenav" [menuOverflow]="menuOverflow" [menuPosition]="menuPosition" [menuTop]="menuTop" [menuBackground]="menuBackground"></app-header-mobile>
    </div>
    <ng-container *ngIf="!isCheckout">
      <div class="theme-container">
        <app-breadcrumb *ngIf="sitemapItems" [breadcrumbItems]="sitemapItems"></app-breadcrumb>
      </div>
    </ng-container>
    <div id="omnial-content" class="theme-container content-start" style="position: relative;">
      <router-outlet></router-outlet>
    </div>
    <ng-container>
      <app-footer></app-footer>
      <div *ngIf="showBackToTop" class="d-flex flex-row justify-content-center align-items-center back-to-top transition" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
      </div>
    </ng-container>
  </div>
</mat-sidenav-container>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.4)" size="large" color="#000000" type="line-scale-party" [fullScreen]="true">
  <div class="d-flex flex-row flex-wrap justify-content-center align-items-start">
    <a mat-button color="accent" class="processing-message">Processing, please wait ....</a>
  </div>
  <div class="d-flex flex-row flex-wrap justify-content-center align-items-start" style="margin-top:250px;opacity: 0.2;">
    <div class="flex-auto text-center">
      <button mat-raised-button color="accent" (click)="resetApp()" class="mr-1 text-center">
        <mat-icon class="mat-icon-sm">refresh</mat-icon>
        <div style="font-size:11px;margin-top: -20px;">Stuck? Click to Reset</div>
      </button>
    </div>
  </div>
</ngx-spinner>
<div class="refresh-remind hide-print" *ngIf="needsRefresh">
  <a mat-raised-button (click)="refreshVersion()">
    An update is available, please click here to refresh
  </a>
</div>
