import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer } from 'src/@omnial/_models/customer/customer.model';
import { SiteMapItem } from 'src/@omnial/_models/navigation/site-map.model';
import { SiteMapService } from 'src/@omnial/_services/navigation/sitemap.service';
import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-footer',
    templateUrl: './../../../app/shared/footer/footer.component.html',
    styleUrls: ['./../../../app/shared/footer/footer.component.scss'],
    standalone: false
})

export class FooterComponent implements OnInit, OnDestroy {
  public footerOneLinks: SiteMapItem[] = [];
  public footerTwoLinks: SiteMapItem[] = [];
  public footerThreeLinks: SiteMapItem[] = [];
  public customer: Customer;
  public year = 2020;
  public subscribed = false;
  public cacheBust = '';
  public subscriptions: Subscription[] = [];

  constructor(
    public settings: AppSettings,
    public router: Router,
    public sitemapService: SiteMapService,
    public snackBar: MatSnackBar) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  ngOnInit(): void {
    this.cacheBust = `${environment.frontEnd}${this.router.url}#CacheBust`; // Temp solution to ease admin when changing the catalogue
    this.year = new Date().getFullYear();
    this.subscriptions.push(this.sitemapService.siteMap.subscribe(
      res => {
        if (res) {
          const sitemap = res as SiteMapItem[];
          this.footerOneLinks = sitemap.filter(s => s.footerOne);
          this.footerTwoLinks = sitemap.filter(s => s.footerTwo);
          this.footerThreeLinks = sitemap.filter(s => s.footerThree);
        }
      }));
  }

  public omnialCommerce() : void {
    alert('Omnial Commerce Site Coming Soon');
  }
}
