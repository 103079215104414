import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DropDownAnimation } from 'src/@omnial/_animations/drop-down.animation';
import { Slide, Slider } from 'src/@omnial/_models/catalog/slider.model';
import { Menu, MenuItem } from 'src/@omnial/_models/navigation/menu.model';
import { SevenSpikesService } from 'src/@omnial/_services/catalog/seven.spikes.service';
import { MenuService } from 'src/@omnial/_services/navigation/menu.service';
import { AppSettings } from 'src/app/app.settings';

@Component({
    selector: 'app-menu',
    templateUrl: './../../../app/navigation/menu/menu.component.html',
    styleUrls: ['./../../../app/navigation/menu/menu.component.scss'],
    animations: [DropDownAnimation],
    standalone: false
})
export class MenuComponent implements OnInit, OnDestroy {
  @ViewChildren('childMenu') childMenus: QueryList<any>;
  @Input() inputSideNav: MatSidenav;
  @Input() rollOver: boolean = true;
  @Input() mega: boolean = false;
  @Input() currentItem: MenuItem = null;
  public menu: Menu = null;
  public menuBanners: Slide[] = [];
  public menuSliders: Slider[] = [];
  public enteredButton = false;
  public isMatMenuOpen = false;
  public prevButtonTrigger: any;
  public topLinks = [{ name: 'Home', href: '/', clicks: 0 }];
  public foundLink: any;
  public subscriptions: Subscription[] = [];

  constructor(
    public settings: AppSettings,
    public router: Router,
    public menuService: MenuService,
    public sevenSpikesService: SevenSpikesService) { }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => { sub.unsubscribe(); });
    }
  }

  ngOnInit(): void {
    this.getMenu();
    this.getMenuBanners();
  }

  closeOthers(trigger: MatMenuTrigger): void {
    this.childMenus?.forEach((childMenu) => {
      if (childMenu?.menuTrigger !== trigger) {
        childMenu.closeMenu();
      }
    });
  }

  getMenu(): void {
    this.subscriptions.push(this.menuService.megaMenu.subscribe(
      res => {
        this.menu = res as Menu;
        if (this.menu && this.menu.items && this.menu.items.length > 0) {
          this.populateClicks();
        }
      }));
  }

  getMenuBanners(): void {
    this.menuBanners = [];
    this.menuSliders = [];
    this.settings?.menuBanners?.forEach((menuBanner) => {
      this.subscriptions.push(this.sevenSpikesService.getSlides(menuBanner.widgetZone, null).subscribe(
        res => {
          const banners = res as Slider[];
          if (banners.length === 1 && banners[0].slides?.length === 1) {
            this.menuBanners.push(banners[0].slides[0]);
          } else {
            banners.forEach((banner) => {
              this.menuSliders.push(banner);
            });
          }
        }));
    });
  }

  setCurrentItem(item: MenuItem) {
    this.currentItem = item;
  }

  populateClicks(): void {
    for (const topLevel of this.menu.items) {
      if (topLevel.children && topLevel.children[0]) {
        this.topLinks.push({ name: topLevel.name, href: topLevel.children[0].link, clicks: 0 });
      }
    }
  }

  parentActive(): string {
    return '';
  }
}
